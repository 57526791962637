import React from "react"
import Layout from "components/layout"
import SEO from "components/seo"
import Tile3V from "components/tile3v"
import Breadcrumb from "components/breadcrumb"
import Ad from "components/ad"

const VideoList = ({ pageContext }) => {
  const { title, videos } = pageContext

  const breadcrumbs = [
    ["Operationer", "/operationer"],
    [title, "#"],
  ]

  return (
    <Layout>
      <SEO title={title} />

      <section className="section">
        <div className="container">
          <Breadcrumb items={breadcrumbs} />

          <Ad />

          <Tile3V items={videos} />
        </div>
      </section>
    </Layout>
  )
}

export default VideoList
